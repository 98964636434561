var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: {
      redeemed: _vm.isRedeemed
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.reward.patch,
      "alt": "Reward Image",
      "width": "300px"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-body', [_c('h5', {
    staticClass: "card-title"
  }, [_vm._v(_vm._s(_vm.reward.judul))]), _c('p', {
    staticClass: "card-text"
  }, [_vm._v(_vm._s(_vm.reward.keterangan))]), _c('p', {
    staticClass: "card-text"
  }, [_c('strong', [_vm._v(_vm._s(_vm.reward.nilai) + " Poin")])]), _c('p', {
    staticClass: "card-text"
  }, [_c('strong', [_vm._v(" Kuota : " + _vm._s(_vm.reward.kouta))])]), _vm.is_redeem(_vm.reward.nilai, _vm.rekapPoin) ? _c('b-button', {
    attrs: {
      "disabled": _vm.isRedeemed,
      "variant": "gradient-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.redeemReward(_vm.reward);
      }
    }
  }, [_vm._v("Redeem")]) : _vm.reward.kouta == 0 ? _c('b-button', {
    attrs: {
      "disabled": "",
      "variant": "gradient-primary"
    }
  }, [_vm._v("Redeem")]) : _c('b-button', {
    attrs: {
      "disabled": "",
      "variant": "gradient-primary"
    }
  }, [_vm._v("Redeem")]), _vm.isRedeemed ? _c('div', {
    staticClass: "mt-2 text-success"
  }, [_vm._v("Anda berhasil me-redeem " + _vm._s(_vm.reward.name) + "!")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }